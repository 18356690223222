<script setup>
import { computed } from 'vue';

const props = defineProps(['filter', 'filtersStore']);

const removeDots = (value) => {
  if (!value) return 0;
  if (value === "") return 0;
  return typeof value === 'string' ? value.replace(/\./g, "") : value;
};

const customValue = computed(() => {
  return {
    min: removeDots(
      props.filtersStore.selected_filters[props.filter.slug]?.value?.min
    ),
    max: removeDots(
      props.filtersStore.selected_filters[props.filter.slug]?.value?.max
    ),
  };
});

const setValue = (e, field) => {
  const newValue = { ...customValue.value, [field]: e };
  props.filtersStore.updateValue(props.filter, newValue);
};

</script>

<template>
  <div class="mt-1 pb-3 border-b border-b-slate-200">
    <div class="px-3 mt-3">
      {{ filter.name }}
    </div>
    <div class="w-fit mx-4 flex items-center justify-center gap-2 text-center">
      <WInput
        class="px-3 py-2 text-sm rounded-tl rounded-bl w-1/2"
        type="number"
        :name="`min-price`" :isPrice="true"
        :value="customValue.min"
        @input="(newVal) => setValue(newVal, 'min')" />
      <WInput
        class="px-3 py-2 text-sm rounded-tl rounded-bl w-1/2"
        type="number"
        :name="`max-price`" :isPrice="true"
        :value="customValue.max"
        @input="(newVal) => setValue(newVal, 'max')" />
    </div>
  </div>
</template>
